import React from 'react';
interface StarIconProps {
  width: number;
  height: number;
  strokeColor: string;
  fillColor?: string;
}
const Star = ({
  width,
  height,
  strokeColor,
  fillColor
}: StarIconProps) => {
  return <svg width={width} height={height} fill="none" viewBox="0 0 12 12" data-sentry-element="svg" data-sentry-component="Star" data-sentry-source-file="Star.tsx">
      <path d="M5.73846 1.30494C5.82078 1.05158 6.17922 1.05158 6.26154 1.30494L7.17309 4.11039C7.2099 4.22369 7.31549 4.30041 7.43463 4.30041H10.3844C10.6508 4.30041 10.7616 4.6413 10.5461 4.79789L8.15963 6.53175C8.06325 6.60178 8.02292 6.7259 8.05973 6.83921L8.97128 9.64466C9.0536 9.89802 8.76362 10.1087 8.5481 9.95211L6.16164 8.21825C6.06526 8.14822 5.93474 8.14822 5.83836 8.21825L3.4519 9.95211C3.23638 10.1087 2.9464 9.89801 3.02872 9.64465L3.94027 6.83921C3.97708 6.7259 3.93675 6.60178 3.84037 6.53175L1.45391 4.79789C1.23839 4.6413 1.34915 4.30041 1.61555 4.30041H4.56537C4.68451 4.30041 4.7901 4.22369 4.82691 4.11039L5.73846 1.30494Z" fill={fillColor || strokeColor} stroke={strokeColor} data-sentry-element="path" data-sentry-source-file="Star.tsx" />
    </svg>;
};
export default Star;