import { Chip, CHIP_STATES } from '@dehaat/dds';
import editProductVariantPrice from '@dehaat/kisan-app-bl/api/editProductPrice';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, useState } from 'react';
import ProductIcon from '@/components/icons/ProductIcon';
import { DEFAULT_CURRENCY, EDIT_PRODUCT_PRICE_REASON, VENDOR_ID_COOKIE_NAME } from '@/constants/common';
import useErrorModalState from '@/hooks/useErrorModalState';
import { ProductVariant } from '@/models/Product';
import styles from '@/styles/HideScrollbar.module.css';
import { getCookieValue } from '@/utils/helper';
import formatCurrency from '@/utils/helpers/formatCurrency';
import ErrorModal from '../ErrorModal';
import LazyImage from '../LazyImage';
import Okay from '../modalActions/Okay';
import Spinner, { SPINNER_TYPE } from '../Spinner';
import SubHeading, { VARIANT as SUBHEADING_VARAINT } from '../SubHeading';
interface Props {
  imageUrl: string | null;
  name: string;
  variantList: ProductVariant[];
  variant: ProductVariant;
  dcId?: number;
  onSubmit: (variant: ProductVariant, amount: string) => void;
}
const ModifyPriceDrawer = ({
  imageUrl,
  variantList,
  name,
  variant,
  dcId,
  onSubmit
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const {
    t: tLogin
  } = useTranslation('login');
  const price = Number(variant?.inventory_set[0].price) || 0;
  const {
    errorModalState,
    hideErrorModal,
    setErrorModalState
  } = useErrorModalState();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>(variant);
  const [isPriceUpdating, setIsPriceUpdating] = useState(false);
  const [finalPrice, setFinalPrice] = useState(price.toString());
  const [error, setError] = useState('');
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setFinalPrice(e.target.value);
  };
  const handleSubmit = async () => {
    if (dcId && !isNaN(Number(finalPrice)) && Number(finalPrice) > 0) {
      setIsPriceUpdating(true);
      const response = await editProductVariantPrice(selectedVariant?.id, dcId, Boolean(getCookieValue(VENDOR_ID_COOKIE_NAME)), Number(finalPrice), EDIT_PRODUCT_PRICE_REASON);
      if (response) {
        onSubmit(selectedVariant, finalPrice);
      } else {
        setErrorModalState({
          ...errorModalState,
          show: true
        });
      }
      setIsPriceUpdating(false);
    } else {
      setError(t('zero_price_error'));
    }
  };
  return <div className="bg-white w-full p-4 shadow-[0_-4px_4px_rgba(0,0,0,0.07)] rounded-t-xl" data-sentry-component="ModifyPriceDrawer" data-sentry-source-file="ModifyPriceDrawer.tsx">
      {isPriceUpdating && <Spinner type={SPINNER_TYPE.IN_SCREEN} />}
      <SubHeading variant={SUBHEADING_VARAINT.MEDIUM} className="mb-4" data-sentry-element="SubHeading" data-sentry-source-file="ModifyPriceDrawer.tsx">
        {t('modify_online_price')}
      </SubHeading>
      <div className="flex">
        <div className="relative w-20 h-20">
          <LazyImage alt={name} fallBackIcon={<ProductIcon className="h-20 w-20" />} imageUrl={imageUrl} className="h-20 w-20 object-contain" data-sentry-element="LazyImage" data-sentry-source-file="ModifyPriceDrawer.tsx" />
        </div>
        <div className="mt-2 ml-4">
          <h2 className="text-base font-medium">
            <strong>{name}</strong>
          </h2>
          <p className="text-xs">
            {t('current_price', {
            text: formatCurrency(price)
          })}
          </p>
          <div className={`flex overflow-scroll item-center mt-3 scrollbar-hidden ${styles.scroll}`}>
            {variantList.map(item => <Chip key={item.id} label={item.attribute_value[0].name} state={selectedVariant?.id == item.id ? CHIP_STATES.ACTIVE : undefined} onClick={() => {
            setSelectedVariant(item);
            setFinalPrice(item?.inventory_set[0].price);
          }} />)}
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-2.5 border border-solid border-black rounded-lg mt-5">
        <p className="text-primary-100 text-xs">
          {t('price_for', {
          text: selectedVariant?.attribute_value[0].name
        })}
        </p>
        <div className="flex items-center">
          {DEFAULT_CURRENCY}
          <input autoFocus type="tel" value={finalPrice} onChange={handleInputChange} className="w-full bg-white border-none outline-none" />
        </div>
      </div>
      {error !== '' && <p className="text-error-90">{error}</p>}
      <button onClick={handleSubmit} className="cursor-pointer py-4 mt-3 bg-primary-100 rounded-lg text-white text-center font-nato-semibold w-full w-webkit-fill capitalize">
        {tLogin('submit')}
      </button>
      <ErrorModal {...errorModalState} onClose={hideErrorModal} data-sentry-element="ErrorModal" data-sentry-source-file="ModifyPriceDrawer.tsx">
        <div>
          <p>{t('unable_to_edit_price')}</p>
          <Okay handleClick={hideErrorModal} data-sentry-element="Okay" data-sentry-source-file="ModifyPriceDrawer.tsx" />
        </div>
      </ErrorModal>
    </div>;
};
export default ModifyPriceDrawer;