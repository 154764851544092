import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AVAILABLE_STOCK } from '@/constants/common';
import { CartContext } from '@/context/CartProvider';
import useIsMobile from '@/hooks/useIsMobile';
import { Product, ProductVariant } from '@/models/Product';
import styles from '@/styles/TruncateContent.module.css';
import CustomEventTypes from '@/utils/analytics/customEventTypes';
import { captureViewEvent, trackCustomEvent } from '@/utils/analytics/googleAnalytics';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { formatProductData } from '@/utils/cart';
import { isArray } from '@/utils/helper';
import BottomDrawer from '../BottomDrawer';
import EditIcon2 from '../icons/EditIcon2';
import Star from '../icons/growth/Star';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '../Paragraph';
import HyperLocalAddToCart from './HyperLocalAddToCart';
import InsuranceLabel from './InsuranceLabel';
import ModifyPriceDrawer from './ModifyPriceDrawer';
import ProductListingImage from './ProductListingImage';
import ProductPrice from './ProductPrice';
import ProductVariantList from './ProductVariantList';
import VariantLabel from './VariantLabel';

// import OutOfStockProductCard from '../cart/OutOfStockProductCard'
// import ProductImage from './ProductImage'
// import VariantAddedMessage from './VariantAddedMessage'

const getActiveVariants = (variants: ProductVariant[], listType?: ProductListTypeEnum) => {
  return variants.filter(variant => {
    if (listType === ProductListTypeEnum.SEARCH) {
      return isArray(variant.inventory_set);
    }
    return variant.active && isArray(variant.inventory_set);
  });
};
interface Props {
  hideOutOfStockLabel?: boolean;
  listType?: ProductListTypeEnum;
  position?: number;
  product: Product;
  showSeeDetailsCTA?: boolean;
  showEditPrice?: boolean;
  dcId?: number;
}
const ProductListingCard = ({
  hideOutOfStockLabel = false,
  listType,
  position,
  product,
  // showSeeDetailsCTA = false,
  showEditPrice = false,
  dcId
}: Props) => {
  const {
    image_url,
    name,
    brand
  } = product;
  const {
    currentVendor,
    cartItems
  } = useContext(CartContext);
  const [activeVariants, setActiveVariants] = useState<ProductVariant[]>([]);
  const [showVariantDrawer, setShowVariantDrawer] = useState(false);
  const [showModifyPriceDrawer, setShowModifyPriceDrawer] = useState(false);
  const [isUnavailableAtVendor, setIsUnavailableAtVendor] = useState(false);
  const {
    isMobile
  } = useIsMobile();
  const {
    t
  } = useTranslation('products');
  const bottomDrawerRef = useRef<HTMLDivElement>(null);
  const StockInSS = useMemo(() => sessionStorage?.getItem(AVAILABLE_STOCK),
  // We need updated stock details everything quantity changes for any product
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [cartItems]);
  const availableStock = StockInSS ? JSON.parse(StockInSS) : undefined;
  const stopPropagation = useCallback((e: MouseEvent<HTMLElement>) => {
    if (!isOutOfStock && activeVariants.length > 1) {
      e.stopPropagation();
      e.preventDefault();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [activeVariants]);
  const toggleBottomDrawer = useCallback(() => {
    if (activeVariants.length > 1) {
      setShowVariantDrawer(prev => !prev);
    }
  }, [activeVariants]);
  const eventData = {
    productName: product?.name,
    productBrand: product?.brand,
    productCategory: product?.category?.name,
    fulfilmentType: currentVendor?.fulfillment_type,
    dcName: currentVendor?.company_name,
    dcMobile: currentVendor?.company_phone
  };
  const onEditClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    trackCustomEvent(CustomEventTypes.PRODUCT_EDIT_PRICE_CLICKED, JSON.stringify({
      ...eventData,
      productVariant: activeVariants[0].attribute_value[0].name,
      currentPrice: activeVariants[0].inventory_set[0].price
    }));
    setShowModifyPriceDrawer(true);
  };
  const handleModifyPrice = (selectedVariant: ProductVariant, amount: string) => {
    trackCustomEvent(CustomEventTypes.HYP_PRICED_EDITED, JSON.stringify({
      ...eventData,
      oldPrice: selectedVariant.inventory_set[0].price,
      newPrice: amount
    }));
    const changedVariantIndex = activeVariants.findIndex(item => item.id === selectedVariant.id);
    setActiveVariants(prev => {
      prev[changedVariantIndex].inventory_set[0].price = amount;
      return prev;
    });
    setShowModifyPriceDrawer(false);
  };
  const isOutOfStock = activeVariants.length === 0 || isUnavailableAtVendor;

  // const seeDetailsCTA =
  //   isOutOfStock && showSeeDetailsCTA ? (
  //     <div className="bg-primary-100 rounded-lg px-[19px] py-[11px] text-white mt-4 w-fit">
  //       {t('see_details')}
  //     </div>
  //   ) : null

  let inventorySet = null;
  let actualPrice = 0;
  let discountedPrice = 0;
  if (isArray(activeVariants)) {
    inventorySet = activeVariants[0].inventory_set[0];
    if (inventorySet) {
      actualPrice = Number(inventorySet.price);
      discountedPrice = actualPrice - Number(inventorySet.discount);
    }
  }
  useEffect(() => {
    if (isArray(product.product_variants)) {
      const formatedProduct = formatProductData(product);
      setActiveVariants(getActiveVariants(formatedProduct.product_variants, listType));
    }
  }, [product, StockInSS, listType]);
  useEffect(() => {
    const preventDefault = (e: globalThis.MouseEvent) => e.preventDefault();
    const {
      current
    } = bottomDrawerRef;
    if (current) {
      current.addEventListener('click', preventDefault);
    }
    return () => {
      if (current) {
        current.removeEventListener('click', preventDefault);
      }
    };
  }, [activeVariants]);
  useEffect(() => {
    if (availableStock && availableStock[activeVariants[0]?.id] === 0) {
      setIsUnavailableAtVendor(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariants]);
  const productSlugEnc = encodeURIComponent(product?.slug?.slug);
  const {
    tag: productTag,
    product_intro: productIntro,
    review_ratings
  } = product?.product_meta || {};
  const totalRatings = review_ratings?.total_ratings || '';
  const overallRating = review_ratings?.overall_rating || '';
  const couponCode = product?.coupon_data?.couponCode || '';
  const couponDes = product?.coupon_data?.description;
  return <>
      <Link href={`/product/${productSlugEnc}`} className={`block max-w-[240px]`} onClick={() => {
      if (!isOutOfStock) {
        captureViewEvent({
          type: listType,
          product
        });
        return true;
      }
    }} data-testid={`product-link-${product.id}`} key={product.id} data-sentry-element="Link" data-sentry-source-file="ProductListingCard.tsx">
        <section className={`pb-2 flex flex-col h-[22.5rem] lg:h-[23rem] bg-white px-2 rounded-xl overflow-hidden relative flex-1 hover:shadow-xl transition-shadow duration-300 ease-in-out${isMobile ? 'w-full' : 'w-full'}`}>
          <div className="flex flex-col items-center relative">
            {productTag ? <p className="p-2 bg-[#653BA3] absolute -left-2 w-min min-w-12 text-center rounded-tl-xl rounded-br-xl text-white z-[1] font-semibold text-xs">
                {productTag}
              </p> : null}
            <div className="relative w-full text-center flex text-center justify-center">
              <ProductListingImage imageUrl={image_url} productName={name} isOutOfStock={!hideOutOfStockLabel && isOutOfStock} imageClassName={'w-32 h-36'} outOfStockImageClassName={'w-32 h-36'} fallbackClassName="w-32 h-36" data-sentry-element="ProductListingImage" data-sentry-source-file="ProductListingCard.tsx" />
              {couponCode && <div className={`flex bg-[#FFBB00] p-0.5 rounded absolute z-[1]`} style={{
              transform: 'rotate(-90deg)',
              transformOrigin: 'right center',
              top: '-10px',
              ...(isMobile ? {
                right: '15px'
              } : {
                right: '20px'
              })
            }}>
                  <div className={`p-0.5 border-dashed rounded border-white border items-center px-1 text-[10px]`}>
                    <p className="text-black leading-tight whitespace-nowrap">
                      {couponDes}
                    </p>
                    <div className="flex flex-row">
                      <p className="text-black font-extrabold leading-tight">
                        USE CODE&nbsp;
                      </p>
                      <p className="text-red-900 font-extrabold leading-tight">
                        {couponCode}
                      </p>
                    </div>
                  </div>
                </div>}
            </div>

            {product.insuranceData?.hasInsurance ? <InsuranceLabel className="-mt-6 z-10" /> : null}
          </div>
          <>
            <div className="h-[32px] lg:h-[40px]">
              <Paragraph variant={isMobile ? PARAGRAPH_VARIANT.SMALL : PARAGRAPH_VARIANT.MEDIUM} isHighlighted className={`text-neutral-90 ${styles.truncate_after_two_lines}`} data-sentry-element="Paragraph" data-sentry-source-file="ProductListingCard.tsx">
                {brand?.name ? `${brand.name}-` : null}
                {product.name}
              </Paragraph>
            </div>
            {/* {seeDetailsCTA} */}
            <div className=" h-[32px]">
              {productIntro ? <Paragraph className={`text-xs font-semibold text-neutral-text-light-70 ${styles.truncate_after_two_lines}`}>
                  {productIntro}
                </Paragraph> : null}
            </div>
            <div className="flex flex-row gap-1 items-center mt-1 h-[24px]">
              {overallRating ? <>
                  <div className="bg-[#FFF3C2] p-1 flex flex-row justify-center items-center w-fit gap-1 rounded">
                    <Star width={12} height={12} strokeColor={'#8C6500'} />
                    <p className="text-[#8C6500] text-xs font-medium">
                      {overallRating}
                    </p>
                  </div>
                  {totalRatings ? <p className="text-neutral-text-light-70 font-normal text-xs">
                      {totalRatings} ratings
                    </p> : null}
                </> : null}
            </div>
          </>
          {/* {isOutOfStock ? null : (
            <>
              {availableStock && availableStock[activeVariants[0]?.id] ? (
                <div className="text-error-100 bg-error-10 p-2 mt-3 text-center rounded-md text-sm">
                  {availableStock[activeVariants[0].id] === 1
                    ? t('only_one_item_left')
                    : t('only_few_items_left', {
                        availableQty: availableStock[activeVariants[0].id],
                      })}
                </div>
              ) : (
                <VariantAddedMessage
                  activeVariants={activeVariants.map((v) => v.id)}
                />
              )}
            </>
           )} */}
          <div className="w-full bg-white rounded-b-xl gap-2" onClick={stopPropagation}>
            <div className="flex items-center mb-1 h-[24px]">
              {isOutOfStock ? null : <>
                  <ProductPrice actualPrice={actualPrice} discountedPrice={discountedPrice} showDiscountPercentage />
                  {showEditPrice && <button className="cursor-pointer flex items-center text-primary-100 ml-2" onClick={onEditClick}>
                      <EditIcon2 className="w-3 h-3 stroke-primary-100 fill-white mr-1" />
                      {t('edit_price')}
                    </button>}
                </>}
            </div>

            <VariantLabel variantLabel={activeVariants[0]?.attribute_value[0].name || ''} variantLength={activeVariants.length} onClick={toggleBottomDrawer} isOutOfStock={isOutOfStock} data-sentry-element="VariantLabel" data-sentry-source-file="ProductListingCard.tsx" />
            <HyperLocalAddToCart productVariant={activeVariants[0]} listType={listType} index={position} isOutOfStock={isOutOfStock} data-sentry-element="HyperLocalAddToCart" data-sentry-source-file="ProductListingCard.tsx" />
          </div>
        </section>
      </Link>
      {activeVariants.length > 1 ? <BottomDrawer show={showVariantDrawer} onClose={() => setShowVariantDrawer(false)} ref={bottomDrawerRef} drawerStyle={'md:max-w-xl'}>
          <section className="bg-gray-10 shadow-[0_-4px_4px_rgba(0,0,0,0.07)] rounded-t-xl px-4 pt-4 pb-6">
            <ProductVariantList productVariants={activeVariants} listType={listType} position={position} onContine={() => setShowVariantDrawer(false)} />
          </section>
        </BottomDrawer> : null}
      <BottomDrawer show={showModifyPriceDrawer} onClose={() => {
      setShowModifyPriceDrawer(false);
    }} data-sentry-element="BottomDrawer" data-sentry-source-file="ProductListingCard.tsx">
        {activeVariants?.[0] ? <ModifyPriceDrawer key={activeVariants[0]?.id} variantList={activeVariants} imageUrl={image_url} name={name} variant={activeVariants[0]} onSubmit={handleModifyPrice} dcId={dcId} /> : null}
      </BottomDrawer>
    </>;
};
export default ProductListingCard;